<!--
 * @Author: Liu Hui
 * @Description: 客户标准销售价
-->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="fleet-match-content">
      <YkcDetailPlus class="box-card" :labelWidth="150">
        <div slot="header">
          <span>机构信息</span>
        </div>
        <div class="baseInfo">
          <div class="content">
            <div class="top">
              <div>
                <span class="label">机构名称:</span>
                <span class="value">
                  {{ dealData(detail.name) }}
                </span>
              </div>
              <div>
                <span class="label">机构管理员:</span>
                <span class="value">
                  {{ dealData(detail.manageName) }}
                </span>
              </div>
              <div>
                <span class="label">启充金额:</span>
                <span class="value">
                  {{ dealData(detail.startLimitAmount) }}
                </span>
              </div>
              <div>
                <span class="label">公司名称:</span>
                <span class="value">
                  {{ dealData(detail.companyName) }}
                </span>
              </div>
              <div>
                <span class="label">联系方式:</span>
                <span class="value">
                  {{ dealData(detail.contactInfo) }}
                </span>
              </div>
              <div>
                <span class="label">停充金额:</span>
                <span class="value">
                  {{ dealData(detail.stopLimitAmount) }}
                </span>
              </div>
              <div>
                <span class="label">统一社会信用代码:</span>
                <span class="value">
                  {{ dealData(detail.socialCreditCode) }}
                </span>
              </div>
              <div>
                <span class="label">充电账号:</span>
                <span class="value">
                  {{ dealData(detail.chargeAccountFlow) }}
                </span>
              </div>
              <div>
                <span class="label">机构类型:</span>
                <span class="value">
                  {{ filterData(dictionaryList.orgType, dealData(detail.orgType)) }}
                </span>
              </div>
              <div v-if="detail.orgType === '4'">
                <span class="label">监管平台:</span>
                <span class="value">{{ roleTypeFormat(detail.supervisePlat) }}</span>
              </div>
            </div>
          </div>
        </div>
      </YkcDetailPlus>
      <YkcDetailPlus class="box-card" :labelWidth="150">
        <div slot="header">
          <span>基础资料</span>
        </div>
        <div class="baseInfo">
          <div class="content">
            <div class="top">
              <div>
                <span class="label">对账方式:</span>
                <span class="value">
                  {{
                    filterData(
                      dictionaryList.reconciliationType,
                      dealData(detail.reconciliationType)
                    )
                  }}
                </span>
              </div>
              <div>
                <span class="label">价格推送:</span>
                <span class="value">
                  {{ filterData(dictionaryList.priceFee, dealData(detail.pricePush)) }}
                </span>
              </div>
              <div>
                <span class="label">实时数据费用:</span>
                <span class="value">
                  {{ filterData(dictionaryList.priceFee, dealData(detail.realTimeFee)) }}
                </span>
              </div>
              <div>
                <span class="label">充电订单费用:</span>
                <span class="value">
                  {{ filterData(dictionaryList.priceFee, dealData(detail.chargeOrderFee)) }}
                </span>
              </div>
              <div>
                <span class="label">电量小数:</span>
                <span class="value">
                  {{ filterData(dictionaryList.powerDecimal, dealData(detail.powerDecimal)) }}
                </span>
              </div>
              <div>
                <span class="label">结束充电结果推送:</span>
                <span class="value">
                  {{ filterData(dictionaryList.pushList, dealData(detail.stopChargeResult)) }}
                </span>
              </div>
              <div>
                <span class="label">启动充电结果推送:</span>
                <span class="value">
                  {{ filterData(dictionaryList.pushList, dealData(detail.startChargeResult)) }}
                </span>
              </div>
              <div>
                <span class="label">设备状态:</span>
                <span class="value">
                  {{ filterData(dictionaryList.stationStatus, dealData(detail.stationStatus)) }}
                </span>
              </div>
              <div>
                <span class="label">实时数据:</span>
                <span class="value">
                  {{ filterData(dictionaryList.pushList, dealData(detail.realTimeData)) }}
                </span>
              </div>
              <div>
                <span class="label">实时数据频率:</span>
                <span class="value">
                  {{ dealData(detail.realTimeDataRate) }}
                </span>
              </div>
              <div>
                <span class="label">充电订单消息:</span>
                <span class="value">
                  {{ filterData(dictionaryList.pushList, dealData(detail.chargeOrder)) }}
                </span>
                <!-- s -->
              </div>
            </div>
          </div>
        </div>
      </YkcDetailPlus>
    </div>
  </scroll-layout>
</template>

<script>
  import { unioneOrg } from '@/service/apis';
  import { localGetItem, dealData } from '@/utils/index';

  export default {
    name: 'SellingPriceDetail',
    data() {
      return {
        dictionaryList: {
          status: [
            { id: '0', name: '是' },
            { id: '1', name: '否' },
          ],
          orgType: [
            { id: '3', name: '流量平台' },
            { id: '4', name: '监管平台' },
          ],
          reconciliationType: [
            { id: '1', name: '接口自动' },
            { id: '2', name: '线下手动' },
          ],
          priceFee: [
            { id: '1', name: '标准价' },
            { id: '2', name: '结算价' },
          ],
          pushList: [
            { id: '0', name: '不推送' },
            { id: '1', name: '推送' },
          ],
          stationStatus: [
            { id: '0', name: '不推送' },
            { id: '1', name: '变位推送' },
          ],
          powerDecimal: [
            { id: '2', name: '2位小数' },
            { id: '3', name: '3位小数' },
          ],
        },
        orgType: '', // 车队类型的值
        detail: {
          name: '',
          manageName: '',
          startLimitAmount: '',
          companyName: '',
          contactInfo: '',
          stopChargeResult: '',
          socialCreditCode: '',
          chargeAccountFlow: '',
          orgType: '',
          reconciliationType: '',
          pricePush: '',
          realTimeFee: '',
          chargeOrderFee: '',
          powerDecimal: '',
          startChargeResult: '',
          stationStatus: '',
          realTimeData: '',
          realTimeDataRate: '',
          chargeOrder: '',
        },
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
      };
    },
    computed: {},
    created() {
      const { id } = this.$route.query;
      this.id = id;
      console.log('this.$route', this.$route);
      this.getDetail();
      this.$store
        .dispatch('getDictionaryByKeys', ['FLEET_TYPE', 'PREFERENTIAL_TYPE', 'CONFIGURE_ATTRIBUTE'])
        .then(res => {
          this.dictionary = res;
          console.log('this.dictionary---', this.dictionary);
        });
    },
    methods: {
      dealData,
      filterData(array, id) {
        const resultItem = array.filter(item => item.id === id);
        console.log(resultItem);
        return (resultItem[0] && resultItem[0].name) || '数据错误';
      },
      roleTypeFormat(row) {
        const supervise_plat = localGetItem('dictionary')?.supervise_plat || [];
        const [property] = supervise_plat.filter(item => item.code === row);
        return property ? property.name : '——' || '——';
      },
      /**
       * 获取详情数据
       */
      getDetail() {
        const { id } = this.$route.query;
        unioneOrg.detail({ id }).then(response => {
          console.log('getDetail response', response);
          Object.assign(this.detail, response);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .fleet-match-content {
    padding: 10px;
    display: flex;
    flex-flow: column;
    .box-card {
      // width: 50%;
      margin: 5px 0;
      .baseInfo {
        display: flex;
        font-size: 14px;
        .header {
          width: 160px;
          display: flex;
          justify-content: center;
          .head {
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            img {
              width: 80px;
              height: 80px;
              border-radius: 80px;
            }
            .level {
              position: relative;
              height: 30px;
              width: 90px;
              border-radius: 10px;
              bottom: 25px;
              color: #fff;
              text-align: right;
              padding-right: 6px;
              line-height: 17px;
              font-weight: 700;
              font-style: italic;
              font-size: 13px;
              background-size: 100%;
              padding: 8px 8px 0 10px;
              box-sizing: border-box;
              left: -7px;
              background-repeat: no-repeat;
              &.level1 {
                background-image: url('../../../../assets/images/vip1.png');
              }
              &.level2 {
                background-image: url('../../../../assets/images/vip2.png');
              }
              &.level3 {
                background-image: url('../../../../assets/images/vip3.png');
              }
              &.level4 {
                background-image: url('../../../../assets/images/vip4.png');
              }
              &.level5 {
                background-image: url('../../../../assets/images/vip5.png');
              }
              // &::
            }
          }
        }
        .content {
          flex-grow: 1;
          display: flex;
          flex-wrap: wrap;
          .label {
            color: #9b9b9b;
            font-size: 12px;
            margin-right: 10px;
          }
          .value {
            font-weight: 400;
            font-size: 12px;
          }
          .top {
            display: flex;
            flex-wrap: wrap;
            & > div {
              width: calc(100% / 3);
              height: 30px;
              line-height: 30px;
              display: flex;
              .label {
                color: #9b9b9b;
                font-size: 12px;
                margin-right: 10px;
              }
              .value {
                font-weight: 400;
                font-size: 12px;
              }
            }
          }
        }
      }
      .vipInfo {
        display: flex;
        .item {
          border: 1px solid #3773fc80;
          background-color: #3773fc1a;
          .tRight {
            background-color: #3773fc24;
            color: #4d8cfd;
            & > :last-child {
              margin-left: 4px;
            }
          }
        }
      }
      .userInfo {
        display: flex;
        .item {
          border: 1px solid #44d7b680;
          background-color: #44d7b61a;
          .tRight {
            background-color: #44d7b624;
            color: #02c498;
            & > :last-child {
              margin-left: 4px;
            }
          }
        }
      }

      .item {
        position: relative;
        width: calc((100% - 20px) / 3);
        border-radius: 10px;
        height: 188px;
        margin-right: 20px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        &:last-child {
          margin-right: 0px;
        }
        .title {
          position: absolute;
          width: 100%;
          font-size: 14px;
          font-weight: bold;
          top: 0;
          left: 0;
          padding: 20px;
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;
          .tRight {
            width: 76px;
            height: 32px;
            display: flex;
            justify-content: center;
            border-radius: 12px;
            align-items: center;
            cursor: pointer;
          }
        }
        .value {
          font-weight: 600;
          font-size: 40px;
          height: 56px;
          line-height: 56px;
        }
        .link {
          color: #4d8cfd;
          font-size: 12px;
          height: 17px;
          line-height: 17px;
          cursor: pointer;
        }
      }
      .itembg {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 72px;
        height: 72px;
        background-repeat: no-repeat;
        background-size: 100%;
        &.bg1 {
          background-image: url(../../../../assets/images/itembg1.png);
        }
        &.bg2 {
          background-image: url(../../../../assets/images/itembg2.png);
        }
        &.bg3 {
          background-image: url(../../../../assets/images/itembg3.png);
        }
        &.bg4 {
          background-image: url(../../../../assets/images/itembg4.png);
        }
        &.bg5 {
          background-image: url(../../../../assets/images/itembg5.png);
        }
        &.bg6 {
          background-image: url(../../../../assets/images/itembg6.png);
        }
      }
      .ellipsis {
        width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      table {
        width: 100%;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        th {
          height: 44px;
        }
        td {
          height: 40px;
        }
        th,
        td {
          border-bottom: 1px solid #ccc;
          border-right: 1px solid #ccc;
        }
      }
    }
  }
  ::v-deep .levelInfo {
    // height: 500px;
    .card {
      height: 160px;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-size: 100%;
      padding: 30px;
      box-sizing: border-box;
      .title {
        font-size: 26px;
        font-family: YouSheBiaoTiHei;
        font-weight: bold;
        font-style: italic;
        // -webkit-background-clip: text;
        color: transparent;
        margin-bottom: 28px;
        display: inline-block;
      }
      .text {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-bottom: 9px;
        span {
          font-weight: bold;
          font-size: 20px;
          margin-right: 2px;
        }
      }
      .progress {
        background: rgba(255, 255, 255, 0.4);
        border-radius: 40px;
        height: 6px;
        .valueLine {
          width: 0px;
          height: 100%;
          border-radius: 40px;
        }
      }
      &.vip1 {
        background-image: url('../../../../assets/images/vipbg1.png');
        .title {
          background-image: linear-gradient(90deg, #1779fe 0%, #8dbdff 100%);
        }
        .text {
          color: #1779fe;
        }
        .progress .valueLine {
          background: linear-gradient(90deg, #9fc8ff 0%, #1779fe 100%);
        }
      }
      &.vip2 {
        background-image: url('../../../../assets/images/vipbg2.png');
        .title {
          background-image: linear-gradient(90deg, #415367 0%, #9fb4cc 100%);
        }
        .text {
          color: #415367;
        }
        .progress .valueLine {
          background: linear-gradient(90deg, #b6cee9 0%, #415367 100%);
        }
      }
      &.vip3 {
        background-image: url('../../../../assets/images/vipbg3.png');
        .title {
          background-image: linear-gradient(90deg, #b75500 0%, #ff9c03 100%);
        }
        .text {
          color: #b75500;
        }
        .progress .valueLine {
          background: linear-gradient(90deg, #ff9c03 0%, #b75500 100%);
        }
      }
      &.vip4 {
        background-image: url('../../../../assets/images/vipbg4.png');
        .title {
          background-image: linear-gradient(90deg, #3623cf 0%, #947cff 100%);
        }
        .text {
          color: #3623cf;
        }
        .progress .valueLine {
          background: linear-gradient(90deg, #947cff 0%, #3623cf 100%);
        }
      }
      &.vip5 {
        background-image: url('../../../../assets/images/vipbg5.png');
        .title {
          background-image: linear-gradient(90deg, #f7ca72 0%, #f7de9c 100%);
        }
        .text {
          color: #f7de9c;
        }
        .progress .valueLine {
          background: linear-gradient(90deg, #f7de9b 0%, #f7ca72 100%);
        }
      }
    }
    .quanyi {
      margin-top: 20px;
      border-radius: 30px;
      border: 1px solid #ebebf0;
      padding: 20px 30px;
      & > * {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        &.title {
          font-size: 14px;
          font-weight: bold;
        }
        &._item {
          display: flex;
          img {
            width: 40px;
            height: 40px;
            margin-right: 20px;
          }
          & > div {
            flex-grow: 1;
            font-size: 12px;
            font-weight: 400;
            .name {
              color: #9b9b9b;
              margin-bottom: 6px;
            }
            .text {
              line-height: 17px;
            }
          }
        }
      }
    }
  }
  ::v-deep .coupon {
    ._item {
      display: flex;
      .left {
        width: 73px;
        height: 75px;
        background: url('../../../../assets/images/coupon.png') no-repeat;
        background-size: 100%;
        .money {
          color: #90552f;
          font-size: 12px;
          margin-top: 6px;
          text-align: center;
          height: 23px;
          span {
            font-family: AlibabaPuHuiTi_2_85_Bold;
            font-size: 18px;
            font-weight: bold;
          }
        }
        .param {
          color: #f9f9f9;
          font-size: 10px;
          margin-top: 10px;
          padding: 0 2px;
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
        }
      }
      .right {
        flex-grow: 1;
        margin-left: 14px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        .title {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 8px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .info {
          margin-bottom: 5px;
          color: #9b9b9b;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
  ::v-deep .card {
    ._item {
      display: flex;
      .left {
        width: 73px;
        height: 75px;
        background: url('../../../../assets/images/card.png') no-repeat;
        background-size: 100%;
        .money {
          color: #90552f;
          font-size: 12px;
          margin-top: 6px;
          text-align: center;
          height: 23px;
          span {
            font-family: AlibabaPuHuiTi_2_85_Bold;
            font-size: 18px;
            font-weight: bold;
          }
        }
        .param {
          color: #f9f9f9;
          font-size: 10px;
          margin-top: 10px;
          padding: 0 2px;
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
        }
      }
      .right {
        flex-grow: 1;
        margin-left: 14px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        .title {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 8px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .info {
          margin-bottom: 5px;
          color: #9b9b9b;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
</style>
